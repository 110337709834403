import React from 'react'
import { ClipLoader } from 'react-spinners'

//import Auth from '../server/auth'
import IndexLayout from '../layouts'
//import useComponentDidMount from '../useComponentDidMount'
import { useState, useEffect } from "react";
//import { handleAuthentication } from "../server/auth"


function useComponentDidMount(onMounted) {
  const [mounted, setMounted] = useState(false)
  useEffect(
    () => {
      setMounted(true)

      onMounted()
    },
    [mounted]
  )
}

const AuthCallbackPage = ( {pathContext: { locale }}) => {
  useComponentDidMount(() => {
   // handleAuthentication()
  })

  return (
    <IndexLayout locale={locale}>
      <h1>
        R
      </h1>
      <ClipLoader sizeUnit="px" size={150} />
    </IndexLayout>
  )
}

export default AuthCallbackPage
